
import React, { useContext, useEffect, useMemo } from "react";
import { useMediaQuery } from "@mui/material";
import { BoxFC } from "components/BoxCustom";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { UserContext } from "contexts/UserContext";
import { loadMSData } from "utils";
import { Box } from "@mui/system";
import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import ClientToday from "pages/Client/ClientToday/ClientToday";
import { Redirect } from "react-router-dom";
import Loading from "components/Loading";
import { Container, AppBar } from 'components/appBarStyle'
import DesktopToolbar from "./DesktopToolbar";
import dayjs from "dayjs";
import ActiveJobOrder from "../ActiveJobOrder/ActiveJobOrder";
import SubcontractJobOrder from "pages/Menu1/SubcontractJobOrder/SubcontractJobOrder";

const menuData = [
  { path: "/subcontract/ActiveJobOrder", label: "งานปัจจุบัน" },
]
const ClientLayout = () => {

  const { user, setUser } = useContext(UserContext);
  const { isLoading, ax, msData, setMSData } = useContext(GlobalStateContext);

  const pms = useMemo(() => {
    return (user?.Pms?.map(pm => pm.Code)) || []
  }, [user])


  useEffect(() => {
    loadMSData(ax, setMSData, setUser)
  }, [setMSData, setUser, ax])

  useEffect(() => {
    dayjs.locale('en')
  }, [])
  console.log("ClientLayout pms::", pms)
  return (

    msData &&
    <BoxFC>
      <AppBar position="fixed">
        <DesktopToolbar menuData={menuData} />
      </AppBar>
      <Container>
        <Box component="main" height="100%" width="100%" sx={{ p: 1, mt: 1 }}>
          <Switch>
            <Route path={`/subcontract/ActiveJobOrder`}><SubcontractJobOrder /></Route>
            <Route path={`*`}><Redirect to="/404" /></Route>
          </Switch>
        </Box>
      </Container>
      {
        isLoading && <Loading />
      }
    </BoxFC>
  )
}

export default ClientLayout