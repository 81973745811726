import { BookmarkAdded, BookmarkBorder, Cancel, CheckCircle, CreditCard, CreditScore, Description, ImageRounded, MailOutline, MarkEmailRead } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { BoxFR } from "components/BoxCustom";
import { jobOrderColNm } from "constants/jobOrderConstant";
import dayjs from "dayjs";

const iconProps = {
  color: "primary",
  sx: { width: 30, height: 30 }
}
const iconPropsDisable = {
  color: "disabled",
  sx: { width: 30, height: 30 }
}

const calculateBgColor = ({ row }) => {
  if (!row.ShorData || row.ShorData.length === 0) {
    return null
  } else {
    let data = []
    if (row.ShorData.length === 1) {
      data = row.ShorData[0]
    } else {
      let maxData = row.ShorData[0]
      for(let i = 1; i < row.ShorData.length; i++) {
        if (dayjs(row.ShorData[i].TagData.PCItmTm).isAfter(dayjs(maxData.TagData.PCItmTm))) {
          maxData = row.ShorData[i]
        }
      }
      data = maxData
    }
    // console.log("data", data, row)
    if (data.TagData?.Vssl === row.Vssl && data.TagData?.RtnPOIId === row.RtnPOIId) {
      return "palegreen"
    } else {
      return "hotpink"
    }
  }
}

const ToolTipIcon = ({title, icon}) => {
  return (
    <Tooltip title={title} arrow placement="top">
      {icon}
    </Tooltip>
  )
}
export const columns = [
  {
    field: "No",
    headerName: "No.",
    width: 50,
    align: 'center',
    cellClassName: 'grey-cell'
  },
  {
    field: "ScNNm",
    headerName: "รถร่วม",
    width: 100,
    cellClassName: 'grey-cell'
  },
  {
    field: "JobOrdId",
    headerName: jobOrderColNm.JobOrdId,
    width: 90,
    cellClassName: 'grey-cell'
  },
  {
    field: "JobSNm",
    headerName: "งาน",
    width: 120,
    cellClassName: 'grey-cell'
  },
  {
    field: "Loc",
    headerName: "สถานที่",
    valueGetter: ({row}) => `${row.TakePlc||""}-${row.Loc||""}-${row.RtnPlc||""}`,
    width: 180,
    cellClassName: 'grey-cell'
  },
  {
    field: "AptTm",
    headerName: jobOrderColNm.AptTm,
    width: 110,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
    cellClassName: 'grey-cell'
  },
  {
    field: "RtnDteEn",
    headerName: jobOrderColNm.RtnDteEn,
    width: 110,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
    cellClassName: 'grey-cell'
  },
  {
    field: "FinDte",
    headerName: jobOrderColNm.FinDte,
    width: 110,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
    cellClassName: 'grey-cell'
  },
  {
    field: "ContSizeFull",
    headerName: jobOrderColNm.ContSizeFull,
    width: 80,
    cellClassName: 'grey-cell'
  },
  {
    field: "IsRdyTake",
    headerName: "สถานะ",
    width: 70,
    align: "center",
    cellClassName: 'grey-cell',
    renderCell: (params) => {
      if (params.row.ShpmTypId === 1) {
        let icon = null
        let text = ""
        if (params.row.ContDoc) {
          icon = <Description  {...iconProps} />
          text = "มีใบกำกับตุ้แล้ว"
        } else if (params.row.IsSndMail) {
          icon = <MarkEmailRead  {...iconProps} />
          text = "มีส่ง Email แล้ว"
        } else if (params.row.ContNo) {
          icon = <MailOutline {...iconPropsDisable} />
          text = "รอส่ง Email"
        } else if (params.row.IsRdyTake) {
          icon = <BookmarkAdded {...iconProps} />
          text = "เช็คบุ๊คแล้ว"
        } else {
          icon = <BookmarkBorder {...iconPropsDisable} />
          text = "รอเช็คบุ๊ค"
        }
        return <BoxFR bgcolor={calculateBgColor(params)}
          flex={1} alignSelf="stretch" justifyContent="center"><ToolTipIcon title={text} icon={icon} /></BoxFR>
      } else if (params.row.ShpmTypId === 2) {
        if (params.row.IsRdyTake) {
          return <ToolTipIcon title="รอการ์ด" icon={<CreditScore {...iconProps} />} />
        } else {
          return <ToolTipIcon title="ได้การ์ดแล้ว" icon={<CreditCard {...iconPropsDisable} />} />
        }
      } else {
        if (params.row.IsRdyTake) {
          return <ToolTipIcon title="พร้อมวิ่งงาน" icon={<CheckCircle color="success" sx={{ width: 30, height: 30 }} />} />
        } else {
          return <ToolTipIcon title="รอเอกสาร" icon={<Cancel color="action" sx={{ width: 30, height: 30 }} />} />
        }
      }
    },
  },
  {
    field: "NumFile",
    headerName: "รูปภาพ",
    width: 70,
    align: 'center',
    renderCell: (params) => (
      <IconButton sx={{p:0}}>
        <ImageRounded color={params.value ? "primary" : "action"} />
      </IconButton>
    )
  },
  {
    field: "ContNo",
    headerName: jobOrderColNm.ContNo,
    width: 120,
    editable: true
  },
  {
    field: "SealNo",
    headerName: jobOrderColNm.SealNo,
    width: 120,
    editable: true
  },
  {
    field: "TareWt",
    headerName: jobOrderColNm.TareWt,
    width: 120,
    editable: true
  },
  {
    field: "DrvNm",
    headerName: "ชื่อ-นามสกุล พขร.",
    width: 120,
    editable: true
  },
  {
    field: "Tel",
    headerName: "หมายเลขโทรศัพท์",
    width: 120,
    editable: true
  },
  {
    field: "LicNm",
    headerName: "ทะเบียนหัว",
    width: 120,
    editable: true
  },
  {
    field: "TlrLicNm",
    headerName: "ทะเบียนหาง",
    width: 120,
    editable: true
  },
  {
    field: "CitzId",
    headerName: "เลขที่บัตรประชาชน",
    width: 120,
    editable: true
  },
  {
    field: "TukLicId",
    headerName: "เลขที่ใบขับขี่",
    width: 120,
    editable: true
  },
  {
    field: "ArvTm",
    headerName: jobOrderColNm.ArvTm,
    width: 120,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },
  {
    field: "LevTm",
    headerName: jobOrderColNm.LevTm,
    width: 120,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },
  // {
  //   field: "Rmk",
  //   headerName: "หมายเหตุ",
  //   flex: 1,
  //   minWidth: 200,
  // },
]