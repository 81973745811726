
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box, Button, Dialog, DialogContent, FormControlLabel, Radio, RadioGroup, TextField, Typography } from "@mui/material"
import ModAccName from "components/ModAccName";
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader";
import { initDialogData, jobColNm, jobApiNm } from "constants/jobConstant";
import useDialogOpen from "hooks/useDialogOpen";
import ComboBox from "components/ComboBox";
import { validate, loadMSData } from "utils";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import PaperComponent from "components/PaperComponent";
import { alertConfirmDelete, alertWarning } from "components/Alert";
import { initFileUploadData } from "constants/fileUploadConstant";
import { saveFile } from "components/FileUplaod/saveFile";
import { FOLDER_JOB_FILE } from "constants/serverConstant";
import FileUpload from "components/FileUplaod/FileUpload";
import CheckboxFormControl from "components/CheckboxFormControl";
import { BoxFR } from "components/BoxCustom";
import TagBox from "components/TagBox";
import TextFieldChangeLanguage from "components/TextFieldChangeLanguage";
import CustomerDialog from "../CustomerManagement/CustomerDialog";
import POIDialog from "../POIManagement/POIDialog";
import { CloudDownloadRounded, SearchRounded } from "@mui/icons-material";
import SearchCompanyAddrDialog from "components/SearchCompanyAddrDialog";
import { cusApiNm } from "constants/cusConstant";

let originalDialogData = null
let originalTagIds = []
//let initCenter = null

const JobDialog = ({ state, fn }) => {
  const { dialogOpen, selectedId, selectedCusId } = state
  const [dialogSearchAddrOpen, setDialogSearchAddrOpen] = useState(false)
  const { setDialogOpen, onFinish } = fn
  const [dialogData, setDialogData] = useState(initDialogData)
  const [fileUploadData, setFileUploadData] = useState(initFileUploadData())
  const [tagData, setTagData] = useState([])
  const [dialogCusOpen, setDialogCusOpen] = useState(false)
  const [dialogPOIOpen, setDialogPOIOpen] = useState(false)
  const { ax, msData, setMSData } = useContext(GlobalStateContext)

  // const latLng = useMemo(() => {
  //   const { Lat, Lng } = dialogData
  //   return Lat === null || Lng === null ? null : { Lat, Lng }
  // }, [dialogData])

  const poiComboMod = useMemo(() => {

    const jobLoc = []
    const otherLoc = []
    for (const item of msData.poiComboWithAdd) {
      if (item.refId === 4) {
        jobLoc.push(item)
      } else {
        otherLoc.push(item)
      }
    }
    const orderedData = [...jobLoc, ...otherLoc]
    if (selectedCusId > 0) {
      return orderedData
    } else {
      const modData = orderedData.filter(item => item.id > 0)
      modData.unshift({ id: -1, label: "เพิ่ม สถานที่", group: "เพิ่มข้อมูล" })
      return modData
    }
  }, [msData.poiComboWithAdd, selectedCusId])

  const modifyData = useCallback((dialogData) => {
    dialogData.IsHeadBrch = dialogData.RcptBrchNo === 0 ? 1 : 0
    const serverFiles = dialogData.Files.map(item => ({
      id: item.JobFileId,
      fileNm: item.FileNm,
      label: item.Label,
      oLabel: item.Label,
      JobId: selectedId,
      isEdit: false,
      file: null,
      imgLink: `${window.location.origin}/${FOLDER_JOB_FILE}${item.JobId}/${item.FileNm}`,
    }))
    originalDialogData = { ...dialogData }
    delete originalDialogData.Files
    originalTagIds = dialogData.Tags.map(item => ({ id: item.TagId, label: item.Name }))
    // originalPOIIds = dialogData.POIs.map(item => ({ id: item.POIId, label: item.Name }))
    setTagData(originalTagIds)
    // setTagData(dialogData.Tags.map(item=>item.TagId))
    setFileUploadData({ ...initFileUploadData(), serverFiles })
    // initCenter = { lat: dialogData.Lat, lng: dialogData.Lng }

    return dialogData
  }, [selectedId])

  const modInitDialogData = useMemo(() => {
    if (selectedCusId) {
      return {
        ...initDialogData,
        CusId: selectedCusId
      }
    } else {
      return initDialogData
    }
  }, [selectedCusId])

  useDialogOpen(dialogOpen, selectedId, jobApiNm.getJob, "JobId", setDialogData, modInitDialogData, modifyData)

  const validation = useMemo(() => ({
    Name: dialogData.Name === "",
    SName: dialogData.SName === "",
    Abbr: dialogData.Abbr === "" || dialogData.Abbr.length > 5,
    CusId: dialogData.CusId == null,
    RcptNm: dialogData.RcptNm === "",
    RcptAddr: dialogData.RcptAddr === "",
    RcptTaxId: dialogData.RcptTaxId === "",
  }), [dialogData])

  const dialogDataProp = useCallback((name) => ({
    variant: "outlined",
    size: "small",
    name: name,
    label: jobColNm[name],
    value: dialogData[name] || "",
    error: validation[name],
    onChange: (e) => { setDialogData((oldState) => ({ ...oldState, [name]: e.target.value })) }
  }), [dialogData, validation])

  const insertUpdateJob = useCallback(async (dialogData) => {
    const dialogDataOnly = { ...dialogData }
    delete dialogDataOnly.Files
    let success = true
    let hasChange = false
    let result;
    let jobId = selectedId

    if (dialogData.JobId > 0) {
      if (JSON.stringify(originalDialogData) !== JSON.stringify(dialogDataOnly)) {
        result = await ax.post(jobApiNm.updateJob, dialogData);
        if (result.data) {
          success = true
          hasChange = true
        } else {
          success = false
        }
      }

      if (JSON.stringify(originalTagIds) !== JSON.stringify(tagData)) {
        const value = await ax.post(jobApiNm.updateJobTag, { JobId: jobId, TagIds: tagData.map(item => item.id) })
        if (value.data) {
          hasChange = true
        } else {
          success = false
        }
      }

    } else {
      dialogData.TagIds = tagData.map(item => item.id)
      result = await ax.post(jobApiNm.insertJob, dialogData);
      jobId = result.data
      hasChange = true
    }


    if (fileUploadData.addedFiles.length > 0 || fileUploadData.deletedFiles.length > 0) {
      hasChange = true
    }
    await saveFile({
      ax,
      alertWarning,
      fileUploadData,
      setFileUploadData,
      apiUpdateFileName: jobApiNm.updateJobFileName,
      apiDeleteFile: jobApiNm.deleteJobFile,
      apiAddFile: jobApiNm.uploadJobFile,
      key: "JobFileId",
      refKey: "JobId",
      refValue: jobId
    })


    loadMSData(ax, setMSData, null)

    if (success) {
      setDialogOpen(false);
    }
    if (hasChange) {
      onFinish()
    }
  }, [ax, setMSData, fileUploadData, onFinish, selectedId, setDialogOpen, tagData])

  const deleteJob = useCallback((jobId) => {
    alertConfirmDelete(() => {
      ax.post(jobApiNm.deleteJob, { JobId: jobId }).then(value => {
        if (value.data) {
          onFinish()
          setDialogOpen(false);
          loadMSData(ax, setMSData, null)
        }
      })
    })
  }, [ax, setMSData, onFinish, setDialogOpen])

  const handleSelectCusId = useCallback((id) => {
    if (id === -1) {
      setDialogCusOpen(true)
      setDialogData(o => ({ ...o, CusId: null }))
    } else {
      setDialogData(o => ({ ...o, CusId: id }))
    }
  }, [])

  const onCusFinish = useCallback(async (id) => {
    await loadMSData(ax, setMSData, null)
    setDialogData(o => ({ ...o, CusId: id }))
  }, [ax, setMSData])

  const handleSelectPOIId = useCallback((id) => {
    if (id === -1) {
      setDialogPOIOpen(true)
      setDialogData(oldState => ({ ...oldState, POIId: null }))
    } else {
      setDialogData(oldState => ({ ...oldState, POIId: id }))
    }

  }, [])
  // const handleSelectPOIId = useCallback((newValue) => {
  //   console.log("newValue::", newValue)
  //   const found = newValue.find(item => item.id === -1)
  //   if (found) {
  //     setDialogPOIOpen(true)
  //   } else {
  //     setPOIData(newValue)
  //   }
  // }, [])

  const onPOIFinish = useCallback(async (id) => {
    await loadMSData(ax, setMSData, null)
    setDialogData(o => ({ ...o, POIId: id }))

    // const newMsData = await loadMSData(ax, setMSData, null)
    // console.log("onPOIFinish id::", id)
    // const newPOI = newMsData.poiCombo.find(item=>item.id===id)
    // console.log("newMsData.poiCombo::", newMsData.poiCombo)
    // console.log("newPOI::", newPOI)
    // if(!newPOI) return
    // setPOIData(o=>[...o, newPOI])
  }, [ax, setMSData])

  const handleIsHeadBrchChange = useCallback((e) => {
    console.log("e.target.value::", e.target.value)
    if (+e.target.value === 1) {
      console.log("e.target.value::1")
      setDialogData(o => ({ ...o, IsHeadBrch: 1, BrchNo: "0" }))
    } else {
      console.log("e.target.value::0")
      setDialogData(o => ({ ...o, IsHeadBrch: 0 }))
    }
  }, [])

  const onSearchAddrFinish = useCallback((addrData) => {
    if (addrData) {
      setDialogData(o => ({
        ...o,
        Name: addrData.Name,
        Addr: addrData.Addr,
        RcptNm: addrData.Name,
        RcptAddr: addrData.Addr,
        RcptTaxId: addrData.TaxId,
      }))
    }
  }, [])

  const handleUseCusData = useCallback(() => {
    if (!dialogData.CusId) {
      return
    }
    ax.post(cusApiNm.getCustomer, { CusId: dialogData.CusId }).then(value => {
      if (value.data && value.data.length > 0) {
        const data = value.data[0]
        setDialogData(o => ({
          ...o,
          RcptNm: data.Name,
          RcptAddr: data.Addr,
          RcptTaxId: data.TaxId,
          RcptBrchNo: data.BrchNo,
          IsHeadBrch: data.BrchNo === 0 ? 1 : 0
        }))
      }
    })
  }, [ax, dialogData.CusId])

  const handleUseOwnData = useCallback(() => {
    const companyData = msData.serverData.CompanyData
    console.log("companyData::", companyData)
    if (companyData) {
      setDialogData(o => ({
        ...o,
        RcptNm: companyData.NameEN,
        RcptAddr: companyData.AddrEN,
        RcptTaxId: companyData.TaxId,
      }))
    }
  }, [msData.serverData.CompanyData])
  useEffect(() => {
    if (!dialogOpen) {
      setFileUploadData(initFileUploadData())
      // setPOIData([])
      // initCenter = null
    }
  }, [dialogOpen])

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth="lg" >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={selectedId > 0 ? "แก้ไขงาน" : "เพิ่มงาน"}
        onDeleteClick={selectedId > 0 ? () => deleteJob(selectedId) : null}
      >
        {selectedId > 0 ? null :
          <Button color="secondary" variant="contained"
            sx={{ ml: 1 }} onClick={() => setDialogSearchAddrOpen(true)}>
            <SearchRounded sx={{ mr: 1 }} />ค้นหาที่อยู่
          </Button>
        }
      </DialogHeader>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={1} mt={2}>

          <BoxFR>
            <CheckboxFormControl label={"Active"}
              checked={dialogData.IsActv === 1 ? true : false}
              onChange={e => setDialogData(o => ({ ...o, IsActv: e.target.checked ? 1 : 0 }))} />

          </BoxFR>
          <Box display="flex" flexDirection="row" width="100%" gap={2} alignItems="stretch">
            <Box display="flex" flexDirection="column" gap={1} flex={1} >

              <ComboBox options={msData.cusComboWithAdd} label={jobColNm.CusSNm} required={true}
                selectedId={dialogData.CusId}
                setSelectedId={handleSelectCusId}
              />
              <TextFieldChangeLanguage {...dialogDataProp("Name")} required />
              <TextFieldChangeLanguage {...dialogDataProp("SName")} required />
              <TextFieldChangeLanguage {...dialogDataProp("Abbr")} required
                helperText={dialogData.Abbr.length > 5 ? "รหัสไม่เกิน 5 ตัวอักษร" : ""} />
              {/* <TextFieldChangeLanguage {...dialogDataProp("Loc")} required /> */}
              {/* <ComboBoxMultiSelect label="สถานที่" value={poiData}
                setValue={handleSelectPOIId} refId={null} options={poiComboMod} /> */}

              <ComboBox options={poiComboMod} label="สถานที่"
                selectedId={dialogData.POIId}
                setSelectedId={handleSelectPOIId}
              />
              <TextFieldChangeLanguage {...dialogDataProp("Addr")} multiline rows={3} />
              <TextFieldChangeLanguage {...dialogDataProp("CmdyTyp")} />
              <TextFieldChangeLanguage {...dialogDataProp("Dscp")} />
              <TextFieldChangeLanguage {...dialogDataProp("Rmk")} />
              <BoxFR width="100%">
                <CheckboxFormControl label={"งานตรงเวลา"}
                  checked={dialogData.IsOnTm === 1 ? true : false}
                  onChange={e => setDialogData(o => ({ ...o, IsOnTm: e.target.checked ? 1 : 0 }))} />

                <TextFieldChangeLanguage {...dialogDataProp("OnTmBuff")} label="เผื่อเวลา(นาที)" type="number" sx={{ flex: 1 }} />
              </BoxFR>
              <TextFieldChangeLanguage {...dialogDataProp("RcptPortCd")} />
              <TagBox value={tagData} setValue={setTagData} tblAbbr="JB" />
            </Box>
            <Box display="flex" flexDirection="column" gap={1} width={475}>
              <BoxFR>
                <Typography>ขื่อ-ที่อยู่ออกใบเสร็จ</Typography>
                <Button variant="contained" onClick={handleUseCusData} disabled={!dialogData.CusId} sx={{flex: 1}}>
                  <CloudDownloadRounded sx={{ mr: 1 }} />ใช้ข้อมูลลูกค้า
                </Button>
                <Button variant="contained" onClick={handleUseOwnData} disabled={!dialogData.CusId} sx={{flex: 1}}>
                  <CloudDownloadRounded sx={{ mr: 1 }} />ใช้ข้อมูลบริษัท
                </Button>
              </BoxFR>
              <BoxFR>
                <TextFieldChangeLanguage {...dialogDataProp("RcptNm")} required sx={{ flex: 1 }} />
              </BoxFR>
              <TextFieldChangeLanguage {...dialogDataProp("RcptAddr")} multiline rows={3} required />
              <BoxFR>
                <TextField {...dialogDataProp('RcptTaxId')} sx={{ width: 150 }} />
                <RadioGroup row value={dialogData.IsHeadBrch} onChange={handleIsHeadBrchChange}>
                  <FormControlLabel value={1} control={<Radio />} label="สำนักงานใหญ่" />
                  <FormControlLabel value={0} control={<Radio />} label="สาขาที่" />
                  <TextField size="small" {...dialogDataProp('RcptBrchNo')} disabled={dialogData.IsHeadBrch === 1}
                    label="เลขที่สาขา" type="number" sx={{ width: 80 }}
                    inputProps={{ style: { textAlign: 'center' } }} />
                </RadioGroup>
              </BoxFR>
              {/* <TextFieldChangeLanguage {...dialogDataProp("RcptTaxId")} required /> */}
              <TextFieldChangeLanguage {...dialogDataProp("CtDscp")} multiline rows={3} />
              <TextFieldChangeLanguage {...dialogDataProp("CtMailCont")} multiline rows={3} />
              <TextFieldChangeLanguage {...dialogDataProp("CtMailInv")} helperText="*** หาส่ง Email หลายคนให้คั่นด้วย จุลภาค(,) " multiline rows={3} />
              <BoxFR>
                <CheckboxFormControl label="ใช้ GPS เวลาเข้า รง." checked={dialogData.IsGPSArvTm === 1}
                  onChange={e => setDialogData(o => ({ ...o, IsGPSArvTm: e.target.checked ? 1 : 0 }))} />
                <CheckboxFormControl label="ใช้ GPS เวลาออก รง." checked={dialogData.IsGPSLevTm === 1}
                  onChange={e => setDialogData(o => ({ ...o, IsGPSLevTm: e.target.checked ? 1 : 0 }))} />
              </BoxFR>
            </Box>
            <Box >

              <FileUpload
                width={300}
                options={msData.serverData.PreText.JobFiles || []}
                fileUploadData={fileUploadData}
                setFileUploadData={setFileUploadData}
              />
            </Box>
          </Box>
          {
            dialogData.JobId > 0 && <ModAccName modDte={dialogData.ModDte} modAcc={dialogData.ModAcc} />
          }
        </Box>

        <CustomerDialog state={{ dialogOpen: dialogCusOpen, selectedCusId: 0 }}
          fn={{
            setDialogOpen: setDialogCusOpen,
            onFinish: onCusFinish
          }} />
        <POIDialog selectedId={0} dialogOpen={dialogPOIOpen} setDialogOpen={setDialogPOIOpen}
          onFinish={onPOIFinish} lastFilter={null} />
        <SearchCompanyAddrDialog
          dialogOpen={dialogSearchAddrOpen}
          setDialogOpen={setDialogSearchAddrOpen}
          onFinish={onSearchAddrFinish} />
      </DialogContent>
      <DialogFooter okText={selectedId > 0 ? "แก้ไขข้อมูล" : "เพิ่มข้อมูล"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={() => validate(validation) && insertUpdateJob(dialogData)} />
    </Dialog>
  )
}

export default React.memo(JobDialog)