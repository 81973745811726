import { jobColNm } from "./jobConstant"
import { shipmentColNm } from "./shipmentConstant"

export const jobOrderApiNm = {
  getDailyJob: "/job-order/getDailyJob",
  getDailyJobForClient: "/job-order/getDailyJobForClient",
  getDailyJobAllDetailV3: "/job-order/getDailyJobAllDetailV3",
  getJobOrder: "/job-order/getJobOrder",
  getJobOrderTruckCoordinate: "/job-order/getJobOrderTruckCoordinate",
  getJobOrderForClientSummary: "/job-order/getJobOrderForClientSummary",
  getContainerData: "/job-order/getContainerData",
  getJobOrderMemo: "/job-order/getJobOrderMemo",
  getContainerClosing: "/job-order/getContainerClosing",
  getJobOrderLog: "/job-order/getJobOrderLog",
  getDataForPrint: "/job-order/getDataForPrint",
  getTruckLocationForClient: "/job-order/getTruckLocationForClient",
  CheckDuplicateContNoInBooking: "/job-order/CheckDuplicateContNoInBooking",
  checkShore: "/job-order/checkShore",
  getJobOrderByDriver: "/job-order/getJobOrderByDriver",
  getJobOrderByDriverV2: "/job-order/getJobOrderByDriverV2",
  getJobOrderJobTag:  "/job-order/getJobOrderJobTag",
  getJobOrderNoDriver: "/job-order/getJobOrderNoDriver",
  getJobOrderFileTable: "/job-order/getJobOrderFileTable",
  getJobOrderFile: "/job-order/getJobOrderFile",
  getSubcontractJobOrder: "/job-order/getSubcontractJobOrder",
  insertJobOrder: "/job-order/insertJobOrder",
  updateSubcontractJobOrderColumn: "/job-order/updateSubcontractJobOrderColumn",
  updateSubcontractJobOrderFromClipboard: "/job-order/updateSubcontractJobOrderFromClipboard",
  updateJobOrderColumn: "/job-order/updateJobOrderColumn",
  appendJobOrderColumn: "/job-order/appendJobOrderColumn",
  updateJobOrder: "/job-order/updateJobOrder",
  deleteJobOrder: "/job-order/deleteJobOrder",
  deleteJobOrders: "/job-order/deleteJobOrders",
  insertUpdateJobOrderContainerStatus: "/job-order/insertUpdateJobOrderContainerStatus",
  updateJobOrderContainerStatusIsCmpt: "/job-order/updateJobOrderContainerStatusIsCmpt",
  sendEmail: "/job-order/sendEmail",
  insertJobOrderTag: "/job-order/insertJobOrderTag",
  updateJobOrderTag: "/job-order/updateJobOrderTag",
  deleteJobOrderTag: "/job-order/deleteJobOrderTag",
  moveJobOrderFile: "/job-order/moveJobOrderFile",
  deleteJobOrderFile: "/job-order/deleteJobOrderFile",
  uploadJobOrderFile: "/job-order/uploadJobOrderFile",
  insertMemo: "/job-order/insertMemo",
  updateJobOrderAllDetail: "/job-order/updateJobOrderAllDetail",
  updateJobOrderAllDetailV3: "/job-order/updateJobOrderAllDetailV3",
  changeShipment: "/job-order/changeShipment",
}


export const jobOrderColNm = {
  JobOrdId: "เลขที่ใบงาน",
  ContSizeFull: "ขนาดตู้",
  ContNo: "หมายเลขตู้",
  SealNo: "หมายเลขซีล",
  AptTm: "เวลานัด",
  ArvTm: "เวลาถึงโรงงาน",
  LevTm: "เวลาออกโรงงาน",
  TakePlc: "สถานที่รับ",
  Loc: "สถานที่ส่ง",
  RtnPlc: "สถานที่คืน",
  TakePOIId: "สถานที่รับ",
  LocPOIId: "สถานที่ส่ง",
  RtnPOIId: "สถานที่คืน",
  TakeDteSt: "เริ่มรับได้",
  TakeDteEn: "ปิดรับ",
  RtnDteSt: "เริ่มคืนได้",
  RtnDteEn: "ปิดคืน",
  TakeAptTm: "เวลา EIR รับตู้",
  TakeArvTm: "เวลาเข้ารับตู้",
  TakeLevTm: "เวลาออกรับตู้",
  RtnAptTm: "เวลา EIR คืนตู้",
  RtnArvTm: "เวลาเข้าคืนตู้",
  RtnLevTm: "เวลาออกคืนตู้",
  FinDte: "เวลาลงข้อมูลจบ",
  JobStsNm: "สถานะตู้",
  Rmk: "หมายเหตุ",
  TlrTyp: "ประเภทหาง",
  TlrTypId: "ประเภทหาง",
  ContSize: "ขนาดตู้",
  ContTyp: "ประเภทตู้",
  ContHq: "Std-Hq",
  ISOCd: "ISO Code",
  TareWt: "Tare Weight",
  FullWt: "Full Weight",
  ContDoc: "ใบกำกับตู้",
  PlnClr: "สีแผนการ",
  PlnTxt: "แผนการ",
  DrvId: "พนักงานขับรถ",
  DrvNm: "พนักงานขับรถ",
  Memo: "Memo",
  DXAmnt: "เงินแนบใบงาน",
  DXNName: "พนักงานขับรถ",
  DXRmk: "หมายเหตุเงินแนบ",
  DIAmnt: "ค่าเที่ยว",
  RcptNm: jobColNm.RcptNm,
  RcptAddr: jobColNm.RcptAddr,
  RcptTaxId: jobColNm.RcptTaxId,
  JobNo: shipmentColNm.JobNo,
  Bkg: shipmentColNm.Bkg,
  CusSNm: shipmentColNm.CusSNm,
  JobSNm: shipmentColNm.JobSNm,
  Agnt: shipmentColNm.Agnt,
  Vssl: shipmentColNm.Vssl,
  ShVssl: shipmentColNm.Vssl,
  Voy: shipmentColNm.Voy,
  ShVoy: shipmentColNm.Voy,
  Port: shipmentColNm.Port,
  ShPort: shipmentColNm.Port,
  ShpmTypId: shipmentColNm.ShpmTypNm,
  ShpmTypNm: shipmentColNm.ShpmTypNm,
  ShpmTypAbbr: shipmentColNm.ShpmTypNm,
  ShRtnPlc: "สถานที่คืน",
  JoRef1: "อ้างอิง1",
  JoRef2: "อ้างอิง2",
  JoRef3: "อ้างอิง3",
  JoRef4: "อ้างอิง4",
  JoRef5: "อ้างอิง5",
  JoRef6: "อ้างอิง6",
  ShRef1: "อ้างอิง1",
  ShRef2: "อ้างอิง2",
  ShRef3: "อ้างอิง3",
  ShRef4: "อ้างอิง4",
  ShRef5: "อ้างอิง5",
  ShRef6: "อ้างอิง6",
  TagNm: "Tag",
  JobTags: "Tag งาน",
  JobOrderTags: "Tag ใบงาน",
  StsTm: "เวลาปล่อยงาน",
  AddTm: "เวลาเพิ่มรูป",
  DrvActTm: "วันที่ปล่อยงาน",
  DrvRmk: "ข้อความถึงพนักงานขับรถ",
  InvNote: "Invoice Note"
}

export const initPageData = {
  JobOrdId: 0,
  ShpmId: 0,
  ShpmTypId: null,
  JobNo: "",
  AptTm: null,
  ContNo: "",
  SealNo: "",
  TakePlc: "",
  Loc: "",
  RtnPlc: "",
  TakePOIId: null,
  LocPOIId: null,
  RtnPOIId: null,
  TakeDteSt: null,
  TakeDteEn: null,
  RtnDteSt: null,
  RtnDteEn: null,
  TakeArvTm: null,
  TakeLevTm: null,
  RtnArvTm: null,
  RtnLevTm: null,
  ContSize: "20",
  ContTyp: "GP",
  ContHq: 0,
  TlrTypId: 1,
  Rmk: "",
  JobId: "",
  PlnTxt: "",
  PlnClr: "#FFFFFF",
  ArvTm: null,
  LevTm: null,
  FinDte: null,
  FullWt: "",
  TareWt: "",
  ISOCd: "",
  IsRdyTake: 0,
  IsSndMail: 0,
  DrvId: null,
  DrvActTm: null,
  DrvRmk: "",
  TukId: null,
  Ref1: "",
  Ref2: "",
  Ref3: "",
  Ref4: "",
  Ref5: "",
  Ref6: "", 
  processDrvId: null,
  advAmnt: 0,
  InvNote: "",
}



export const initComboData = {
  cusDialogValue: null,
  cusDialogText: '',
  jobDialogValue: null,
  jobDialogText: '',
  cusFilterValue: null,
  cusFilterText: '',
  jobFilterValue: null,
  jobFilterText: '',
}


export const act = {
  ON_CHANGE_FILTER: 'ON_CHANGE_FILTER',
  ON_CHANGE_DIALOG_DATA: 'ON_CHANGE_DIALOG_DATA',
  ON_CHANGE_DIALOG_DATA_IMG: 'ON_CHANGE_DIALOG_DATA_IMG',
  ON_CHANGE_DIALOG_COMBO: 'ON_CHANGE_DIALOG_COMBO',
  CLEAR_FILTER: 'FILTER_CLEAR',
  SET_DATA_TABALE: 'SET_DATA_TABALE',
  SET_DIALOG_OPEN: 'SET_DIALOG_OPEN',
  SET_DIALOG_DATA: 'SET_DIALOG_DATA',
  SET_JOB_COMBO_DATA: 'SET_JOB_COMBO_DATA',
}